<template>
	<div>
		<card-component :title="$t('def.processLogs')" icon="domain">
			<b-tabs type="is-toggle" expanded size="is-small">
				<b-tab-item :label="$t('def.updateLogs')">
					<div class="b-table">
						<div
							class="table-wrapper has-mobile-cards has-sticky-header">
							<table
								class="table is-striped is-narrow is-hoverable">
								<thead>
									<tr>
										<td>{{$t('def.status')}}</td>
										<td>{{$t('def.statusReason')}}</td>
										<td>{{$t('def.user')}}</td>
										<td>{{$t('def.amount')}}</td>
										<td>{{$t('def.date')}}</td>
										<td>{{$t('def.operator')}}</td>
										<td
											v-if="
												hasPermission(
													'Deposit.IntegrationLog'
												)
											">
											Entegrasyon Logu
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, key) in activityLogs"
										:key="key">
										<td>
											<b-tag
												size="is-small"
												:type="
													'is-' +
													transactionStatusTypes[
														item.status
													].color
												"
												>
												{{$t('statusTypes.'+item.status.toLowerCase())}}</b-tag
											>
										</td>
										<td>{{ item.statusReason }}</td>
										<td>{{ item.name }}</td>
										<td>
											{{ currencies[val.fromCurrency] }}
											{{ item.total }}
										</td>
										<td>
											{{
												dateFormat(
													item.createdAt,
													dateFormats.normalDateTimeS
												)
											}}
										</td>
										<td>{{ item.creator.username }}</td>
										<td
											v-if="
												hasPermission(
													'Deposit.IntegrationLog'
												)
											">
											<json-viewer
												v-if="item.integrationResponse"
												:value="
													item.integrationResponse
												"
												:expand-depth="0"
												copyable />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</b-tab-item>
				<b-tab-item :label="$t('def.callbackLogs')">
					<b-collapse
						class="card"
						animation="slide"
						v-for="(item, index) of callbackLogs"
						:key="index"
						:open="isOpen === index"
						@open="isOpen = index">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ dateFormatChange(item.createdAt) }}
								</p>
								<a class="card-header-icon">
									<span>
										<b>Status:</b>
										<strong
											:class="{
												'has-text-success':
													item.status === 200,
												'has-text-danger':
													item.status !== 200,
											}">
											{{ item.status }}
										</strong>
									</span>
									<b-icon
										:icon="
											props.open ? 'menu-down' : 'menu-up'
										" />
								</a>
							</div>
						</template>
						<div
							class="card-content">
							<b-field label="Callback URL">
								<b-input v-model="item.url" />
							</b-field>
							<b-field :label="$t('def.request')">
								<b-input
									v-model="item.request"
									type="textarea"
									maxlength="1500"
									size="is-small"
									:rows="5" />
							</b-field>
							<b-field :label="$t('def.response')">
								<b-input
									v-model="item.response"
									type="textarea"
									maxlength="1500"
									size="is-small"
									:rows="5" />
							</b-field>
						</div>
					</b-collapse>
				</b-tab-item>
				<b-tab-item
					v-if="
						val.integrationParams &&
						hasPermission('Deposit.IntegrationParams')
					"
					label="Entegrasyon Logu">
					<b-collapse class="card" animation="slide" :open="true">
						<template #trigger="props">
							<div class="card-header" role="button">
								<p class="card-header-title">
									{{ dateFormatChange(val.createdAt) }}
								</p>
								<a class="card-header-icon">
									<b-icon
										:icon="
											props.open ? 'menu-down' : 'menu-up'
										" />
								</a>
							</div>
						</template>
						<div class="card-content"
							style="height: 450px; overflow: auto">
							<b-field label="İstek">
								<json-viewer
									v-if="val.integrationParams.req"
									:value="val.integrationParams.req"
									:expand-depth="1"
									copyable />
							</b-field>
							<b-field label="Cevap">
								<json-viewer
									v-if="val.integrationParams.res"
									:value="val.integrationParams.res"
									:expand-depth="1"
									copyable />
							</b-field>
						</div>
					</b-collapse>
				</b-tab-item>
			</b-tabs>
		</card-component>
	</div>
</template>

<script>
	import CardComponent from "@/components/CardComponent"
	import { TransactionService as Service } from "@/services"
	import JsonViewer from "vue-json-viewer"

	export default {
		name: "DepositLogs",
		components: { CardComponent, JsonViewer },
		props: ["withdraw"],
		data() {
			return {
				activityLogs: [],
				callbackLogs: [],
				isOpen: 0,
				val: {},
				controlMessage: null,
				controlLoading: false,
			}
		},
		mounted() {
			if (this.withdraw.uuid) this.init()
		},
		methods: {
			dateFormatChange(val) {
				return this.dateFormat(val, this.dateFormats.normalDateTimeS)
			},
			init() {
				Service.edit(this.withdraw.uuid)
					.then((res) => {
						this.val = res.data
						this.activityLogs = res.data.logs
						res.data.callbackLogs.map((item) => {
							item.request = JSON.stringify(item.request)
							item.response = JSON.stringify(item.response)
						})
						this.callbackLogs = res.data.callbackLogs
					})
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
					)
			},
		},
	}
</script>
