<template>
  <div class="container-fluid p-3">

    <div class="columns is-mobile is-flex-wrap-wrap">
      <div class="column is-full">
        <section class="section is-main-section p-4">
          <div class="p-0 box">
            <div class="table-filter m-0">
              <b-field grouped>
                <b-field label="Site" v-if="!$store.state.user.siteId">
                  <b-select expanded size="is-small" v-model="siteId">
                    <option :value="null">-</option>
                    <option :value="item.uuid" v-for="item in sites">{{ item.name }}</option>
                  </b-select>
                </b-field>
                <b-field label="Banka">
                  <b-select expanded size="is-small" v-model="bankId">
                    <option :value="null">-</option>
                    <option :value="item.uuid" v-for="item in banks">{{ item.name }}</option>
                  </b-select>
                </b-field>
                <b-field label="Tarih">
                  <finance-datepicker :lang="datepicker.datePickerLang" v-mask="'##.##.####'" v-model="date" type="date"
                    format="DD.MM.YYYY" />
                </b-field>
                <b-button icon-left="sync" size="is-small" type="is-info" class="ml-2"
                  @click="getFailedTransactions">Yenile</b-button>
                <b-button size="is-small" type="is-dark" class="ml-2" @click="clear">Temizle</b-button>
              </b-field>
            </div>
          </div>
        </section>
      </div>

      <div class="column is-full">
        <section class="section is-main-section">
          <div class="tile is-ancestor is-flex-wrap-wrap">
            <div class="tile is-parent is-3" v-for="(item, key) in statusDatas" :key="key">
              <article class="tile is-child">
                <card-component :title="`${item.status}`" :title-class="`${statusDatasClass[item.status]}`">
                  <div class="is-widget-label is-align-self-flex-start">
                    <h1 class="title">
                      <growing-number :value="item.count" />
                    </h1>
                  </div>

                  <div class="is-widget-label is-align-self-flex-start mt-2" v-if="item.status === 'SUCCESSFUL'">
                    <div class="subtitle is-7 mb-0">
                      Başarı oranı: %{{ successRate }}
                    </div>
                  </div>
                </card-component>
              </article>
            </div>
          </div>

        </section>
      </div>


      <div class="column is-12">

        <section class="section is-main-section p-4">
          <card-component :title="`Başarısız işaretlendikten sonra başarılı olarak güncellenen işlemler. (${statusChangeDatas.length} adet)`">
            <b-table ref="table2" style="overflow:auto" :loading="$store.state.loading && $store.state.loading.length > 0"
              :striped="true" :responsive="true" :hoverable="true" :data="statusChangeDatas">

              <b-table-column label="İşlem" field="actions" sortable v-slot="props">
                <b-button size="is-small" type="is-dark" class="mr-2" @click="logDetail(props.row)">
                  Loglar
                </b-button>
              </b-table-column>
              <b-table-column label="Transaction ID" field="uuid" sortable v-slot="props">
                {{ props.row.uuid }}
              </b-table-column>
              <b-table-column label="Process ID" field="processId" sortable v-slot="props">
                {{ props.row.processId }}
              </b-table-column>
              <b-table-column label="Total" field="total" sortable v-slot="props">
                {{ props.row.total }}
              </b-table-column>
              <b-table-column label="Yatırımcı" field="name" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column label="Tarih" field="createdAt" sortable v-slot="props">
                {{ dateFormat(props.row.createdAt, dateFormats.normalDateTime) }}
              </b-table-column>
              <section slot="empty" class="section">
                <div class="content has-text-grey has-text-centered">
                  <template v-if="$store.state.loading && $store.state.loading.length > 0">
                    <p>
                      <b-icon icon="dots-horizontal" size="is-large" />
                    </p>
                    <p>Fetching data...</p>
                  </template>
                  <template v-else>
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large" />
                    </p>
                    <p>Nothing's here&hellip;</p>
                  </template>
                </div>
              </section>
            </b-table>
          </card-component>
        </section>
      </div>

      <div class="column is-6">

        <section class="section is-main-section p-4">
          <card-component title="Başarısız işlemlerin son log kayıtlarının özeti">
            <b-table ref="table" style="overflow:auto" :loading="$store.state.loading && $store.state.loading.length > 0"
              :striped="true" :responsive="true" :hoverable="true" :data="datas">

              <b-table-column label="Actions" field="actions" sortable v-slot="props">
                <b-button size="is-small" type="is-dark" class="mr-2" @click="getFailedTransactionsSub(props.row.reason)">
                  Detay
                </b-button>
              </b-table-column>
              <b-table-column label="Adet" field="count" sortable v-slot="props">
                {{ props.row.count }}
              </b-table-column>
              <b-table-column label="Hata" field="reason" sortable v-slot="props">
                {{ props.row.reason || 'İşlem panel kullanıcısı tarafından sonlandırıldı' }}
              </b-table-column>
              <section slot="empty" class="section">
                <div class="content has-text-grey has-text-centered">
                  <template v-if="$store.state.loading && $store.state.loading.length > 0">
                    <p>
                      <b-icon icon="dots-horizontal" size="is-large" />
                    </p>
                    <p>Fetching data...</p>
                  </template>
                  <template v-else>
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large" />
                    </p>
                    <p>Nothing's here&hellip;</p>
                  </template>
                </div>
              </section>
            </b-table>
          </card-component>
        </section>
      </div>

      <div class="column is-6" v-if="subDatas.length > 0">
        <section class="section p-4">
          <card-component title="Seçilen hata kayıtlarıyla sonlanmış işlemlerin detaylı log kayıtlarının özeti">
            <b-table ref="table2" style="overflow:auto" :loading="$store.state.loading && $store.state.loading.length > 0"
              :striped="true" :responsive="true" :hoverable="true" :data="subDatas">

              <b-table-column label="Actions" field="actions" sortable v-slot="props">
                <b-button size="is-small" type="is-dark" class="mr-2" @click="getTransactions(props.row.reason)">
                  Detay
                </b-button>
              </b-table-column>
              <b-table-column label="Adet" field="count" sortable v-slot="props">
                {{ props.row.count }}
              </b-table-column>
              <b-table-column label="Hata" field="reason" sortable v-slot="props">
                {{ props.row.reason || 'İşlem panel kullanıcısı tarafından sonlandırıldı' }}
              </b-table-column>

              <section slot="empty" class="section">
                <div class="content has-text-grey has-text-centered">
                  <template v-if="$store.state.loading && $store.state.loading.length > 0">
                    <p>
                      <b-icon icon="dots-horizontal" size="is-large" />
                    </p>
                    <p>Fetching data...</p>
                  </template>
                  <template v-else>
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large" />
                    </p>
                    <p>Nothing's here&hellip;</p>
                  </template>
                </div>
              </section>
            </b-table>
          </card-component>
        </section>
      </div>


      <div class="column is-full" v-if="transactions.length > 0">
        <section class="section p-4">
          <card-component :title="`Seçilen hata kayıtlarını içeren yatırımların listesi. (${transactions.length} adet)`">
            <b-table ref="table2" style="overflow:auto" :loading="$store.state.loading && $store.state.loading.length > 0"
              :striped="true" :responsive="true" :hoverable="true" :data="transactions">

              <b-table-column label="İşlem" field="actions" sortable v-slot="props">
                <b-button size="is-small" type="is-dark" class="mr-2" @click="logDetail(props.row)">
                  Loglar
                </b-button>
              </b-table-column>
              <b-table-column label="Transaction ID" field="uuid" sortable v-slot="props">
                {{ props.row.uuid }}
              </b-table-column>
              <b-table-column label="Process ID" field="processId" sortable v-slot="props">
                {{ props.row.processId }}
              </b-table-column>
              <b-table-column label="Total" field="total" sortable v-slot="props">
                {{ props.row.total }}
              </b-table-column>
              <b-table-column label="Yatırımcı" field="name" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column label="Tarih" field="createdAt" sortable v-slot="props">
                {{ dateFormat(props.row.createdAt, dateFormats.normalDateTime) }}
              </b-table-column>
              <section slot="empty" class="section">
                <div class="content has-text-grey has-text-centered">
                  <template v-if="$store.state.loading && $store.state.loading.length > 0">
                    <p>
                      <b-icon icon="dots-horizontal" size="is-large" />
                    </p>
                    <p>Fetching data...</p>
                  </template>
                  <template v-else>
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large" />
                    </p>
                    <p>Nothing's here&hellip;</p>
                  </template>
                </div>
              </section>
            </b-table>
          </card-component>
        </section>
      </div>
    </div>

  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import LogModal from '@/views/Deposit/Log'
import GrowingNumber from "@/components/GrowingNumber.vue";
import { ReportService as Service, SiteService, BankService } from '@/services'
export default {
  name: 'FaildeTransactionsReport',
  components: {
    CardComponent,
    GrowingNumber
  },
  data() {
    return {
      date: new Date(),
      bankId: null,
      siteId: null,
      sites: [],
      banks: [],
      datas: [],
      subDatas: [],
      transactions: [],
      reason: null,
      statusDatas: [],
      statusChangeDatas: [],
      statusDatasClass: {
        SUCCESSFUL: 'has-background-success has-text-white',
        UNSUCCESSFUL: 'has-background-danger has-text-white',
        PENDING: 'has-background-warning has-text-black',
        WAITING: 'has-background-info has-text-white',
      },
      successRate: 0
    }
  },
  mounted() {
    this.getBanks()
    if (!this.$store.state.user.siteId) this.getSites()
    this.getFailedTransactions()
  },
  methods: {
    getFailedTransactions() {
      this.clear()
      const date = this.dateFormat(this.date, this.dateFormats.dbDate)
      Service.failedTransactionReport({ date, bankId: this.bankId, siteId: this.siteId }).then(res => this.datas = res.data)
      this.getStatusDatas()
      this.getStatusChangeReport()
    },
    getStatusDatas() {
      this.clear()
      const date = this.dateFormat(this.date, this.dateFormats.dbDate)
      Service.status({ date, bankId: this.bankId, siteId: this.siteId }).then(res => {
        this.successRate = 0
        this.statusDatas = res.data
        let successTotal = 0
        let total = 0
        this.statusDatas.map(item => {
          if (item.status === 'SUCCESSFUL' || item.status === 'UNSUCCESSFUL') {
            total += item.count
          }
          if (item.status === 'SUCCESSFUL') successTotal = item.count
        })
        this.successRate = parseFloat((successTotal / total) * 100).toFixed(2)
      })
    },
    getStatusChangeReport() {
      this.clear()
      const date = this.dateFormat(this.date, this.dateFormats.dbDate)
      Service.statusChangeReport({ date, bankId: this.bankId, siteId: this.siteId }).then(res => {
        this.statusChangeDatas = res.data
      })
    },
    getFailedTransactionsSub(reason) {
      this.clear()
      this.reason = reason
      const date = this.dateFormat(this.date, this.dateFormats.dbDate)
      Service.failedTransactionsSubReport({ date, bankId: this.bankId, siteId: this.siteId, reason }).then(res => this.subDatas = res.data)
    },
    getTransactions(reason) {
      const date = this.dateFormat(this.date, this.dateFormats.dbDate)
      Service.failedTransactionsDetailReport({ date, bankId: this.bankId, siteId: this.siteId, reason: this.reason, subReason: reason }).then(res => this.transactions = res.data)
    },
    getSites() {
      SiteService.all({ status: 1 }).then(({ data }) => {
        this.sites = data
      })
    },
    getBanks() {
      BankService.all({ status: 1 }).then(({ data }) => {
        this.banks = data
      })
    },
    clear() {
      this.subDatas = []
      this.transactions = []
      this.reason = null
    },
    logDetail(row) {
      this.openModal({
        component: LogModal,
        styles: { width: 1000, height: 'auto' },
        props: {
          withdraw: { ...row },
        },
      })
    }
  },
  watch: {
    'date': function () {
      this.getFailedTransactions()
    }
  }
}
</script>
